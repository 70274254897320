import React from "react"
import { navigate } from "gatsby"
import styles from "./styles.module.css";
import PlanSideBar from "../../shared/planSideBar";
import { decimal } from '../../common/numFormat';
import { getToken, getUser } from "../../services/auth";
import { orderList, viewGstInvoice } from "../../services/customerInfo";
import { Order_Status, System_Type } from "../../common/constants";
import Tooltip from "../../shared/Tooltip";

var dateFormat = require('dateformat');

class OrderHistory extends React.Component {
    constructor(props) {
        super(props);
        const user = getUser();
        const token = user && user.access_token;
        const isTemp = user && user.id ==='TEMP';

        this.state = {
            token,
            orderList: null,
            isTemp
        }

    }

    componentDidMount() {
        if (this.state.isTemp) {
            navigate('/account/plan')
        } else {
            this.getOrderHistory(this.state.token);
        }
    }

    getOrderHistory = (token) => {
        orderList(token).then(({response,error}) => {
            if (error.status>=400) {
                console.log('ERROR',error);
            } else {
                this.setState({orderList: response.List});
            }
        })
    }

    viewInvoice = (invoiceNum) => {
        viewGstInvoice(getToken(),invoiceNum).then(({error,response}) => {
            if (error.status < 400) {
                this.openPDF(response);
            }
        })
    }

    openPDF(link) {
        if (link) {
            var win = window.open(link, '_blank');
            win.focus();
        }
    }

    render() {
        return (
            <>
                <div className={styles.plan_section}>
                <div className="container-fluid">
                    <div className="row plan-grey-background">
                        <PlanSideBar page={"OrderHistory"}/>
                        <div className={["col-lg-10", "col-md-12", "right_section"].join(" ")}>
                            <div className={[styles.mpr_order_history_section , "p-lg-5","p-md-3"].join( ' ' )}>
                                <div className={["mpr_order_history_title", "mt-5", "pb-4"].join(" ")}>
                                    <div className={["row", "align-items-center"].join(" ")}>
                                        <div className="col-8">
                                            <h2 className="d-inline-block" >Order History
                                                <>
                                                    <span className="qcircle" id={'order-history-tooltip'} data-toggle="tooltip" data-placement="right">?</span>
                                                    <Tooltip text={'Order history is available from June 2019'} target={'order-history-tooltip'}/>
                                                </>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.orderList &&
                                    this.state.orderList.map((order) => 
                                        <OrderItem order={order} key={order.OrderId} viewInvoice={this.viewInvoice}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </>
        )
    }
}

export const OrderItem = ({order,viewInvoice}) => {
    const {InvoiceDt, OrderDt, OrderStatusId, SystemType, OrderType, ProductName, FinalAmount, 
        NumDesktopAddOns, NumCloudAddOns, LicenseExpDt, InvoiceSentToClient=false, InvoiceNum,
        IsLegacyProduct, NumCloudUsers, NumFamiliesAdditional } = order;
    const isOrderDt = new Date(InvoiceDt)<new Date("1/1/2000");
    const date = isOrderDt ? OrderDt : InvoiceDt;
    var isCloud = SystemType === System_Type.CLOUD;
    var orderStatusText = "";
    switch(OrderStatusId) {
        case Order_Status.PAID_AND_PROCESSED:
            orderStatusText = "Paid & Processed";
            break;
        case Order_Status.DECLINED:
            orderStatusText = "Declined";
            break;
        case Order_Status.PENDING:
            orderStatusText = "Pending";
            break;
        case Order_Status.CANCELLED:
            orderStatusText = "Cancelled";
            break;
        default:
            break;
    }
    return (
        <div className={["card", "mpr-card-shadow", "p-md-4", "pl-4", "pr-4", "pt-3", "pb-3", "mb-3"].join(" ")}>
            <div className="row ">
                <div className={["col-md-4","col-lg-4","col-xl-2", "col-6", "mb-2", "mb-xl-0", "order-3", "order-md-1"].join(" ")}>
                    <label className={["mb-2"].join(" ")}>{(isOrderDt ? "Order" : "Invoice") + " Date"}</label> 
                    <h5 className={[styles.invoiceDtContainer,"d-flex flex-row flex-wrap"].join(" ")}><span className={[styles.invoiceDt, InvoiceNum && InvoiceSentToClient ? styles.invoiceExistsMargin : ""].join(" ")}>{dateFormat(date,'mmmm d, yyyy')}</span>
                    {
                        InvoiceNum && InvoiceSentToClient &&
                        <>
                        <span className={styles.invoiceIcon} id={"invoice-icon-"+InvoiceNum} onClick={()=>viewInvoice(InvoiceNum)}><i className={["fa", "fa-file-pdf-o",styles.invoiceIconColor].join(" ")} aria-hidden="true"></i></span>
                        <Tooltip text={"Click to view your GST Invoice"} target={"invoice-icon-"+InvoiceNum}/>
                        </>
                    }
                    </h5>
                </div>
                <div className={["col-md-4","col-lg-4","col-xl-4", "col-6",  "mb-2", "mb-xl-0", "order-1", "order-md-2"].join(" ")}>
                    <label className="mb-2">{ 'Product ' + ((OrderType === 2 || OrderType === 3) ? ' (Upgrade)' : '')}</label>
                    <h5>{ProductName}</h5>
                    {
                        IsLegacyProduct ?
                        <>
                        <span className={["d-block", "mpr_alert_success"].join(" ")}>{!isCloud ? 'Desktop' : 'Cloud'}</span>
                        { NumDesktopAddOns > 0 && <label className={["d-block", "m-0"].join(" ")}>{NumDesktopAddOns + ' Desktop add-on' + (NumDesktopAddOns > 1 ? 's' : '')}</label> }
                        { NumCloudAddOns > 0 && <label className="d-block">{NumCloudAddOns + (isCloud ? ' Add-on' : ' Cloud add-on') + (NumCloudAddOns > 1 ? 's' : '')}</label> }
                        </>
                        :
                        <>
                        { NumCloudUsers > 0 && <label className={["d-block", NumFamiliesAdditional > 0 && "m-0"].join(" ")}>{NumCloudUsers + ' User Login' + (NumCloudUsers > 1 ? 's' : '')}</label> }
                        { NumFamiliesAdditional > 0 && <label className="d-block">{NumFamiliesAdditional + ' Additional ' + (NumFamiliesAdditional > 1 ? 'Families' : 'Family')}</label> }
                        </>
                    }
                    {/* <p>{(NumDesktopAddOns > 0 && NumDesktopAddOns + "Desktop Add-Ons") + (NumCloudAddOns > 0 && (NumDesktopAddOns > 0 ? " + " : NumCloudAddOns + " Cloud Add-Ons") )}</p> */}
                </div>
                <div className={["col-md-4","col-lg-4","col-xl-2", "col-6",  "mb-2", "mb-xl-0", "order-5", "order-md-3"].join(" ")}>
                    <label className="mb-2">Total Amount</label> 
                    <h5>{decimal(FinalAmount)}</h5>
                </div>
                <div className={["col-md-4","col-lg-4","col-xl-2", "col-6", "mb-2", "mb-md-0", "order-2", "order-md-3"].join(" ")}>
                    <label className="mb-2">License Expiry Date</label> 
                    <h5>{dateFormat(LicenseExpDt,'mmmm d, yyyy')}</h5>
                </div>
                <div className={["col-md-4","col-lg-4","col-xl-2", "col-5", "mb-2", "mb-md-0", "order-4", "order-md-4"].join(" ")}>
                    <label className={["mb-2"].join(" ")}>Order Status</label> 
                    <h5>{orderStatusText}</h5>
                </div>
            </div>
        </div>
    )
}

export default OrderHistory