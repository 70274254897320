import React from "react"
import PrivateRoute from "../../components/privateRoute";
import OrderHistory from "../../components/orderHistory";

class Page extends React.Component {
    render() {
        return (
            <PrivateRoute path="/account/order-history" component={OrderHistory} location={this.props.location} showFooter={false} greyBackground={true} seoFields={seoFields}/>
        )
    }
}

const seoFields = {
    description: "",
    // lang,
    // meta,
    // keywords,
    title: "Order History" 
}

export default Page